<template>
  <div class='infantTv'>
    <div class="imgBox">
      <div class="swiper swiper_infant" v-if="pictures.length>0">
        <div class="swiper-wrapper">
          <p v-for="(el, index) in pictures" class="swiper-slide" :key="index">
            <img :src="el" alt="">
          </p>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="introduce">
      <h2><i></i>您好，欢迎来到 <span>{{information.unit}}</span> ！</h2>
      <p>
        {{information.unit}}{{information.introduce?information.introduce:'集水疗、游泳、洗浴、抚触为一体，浴巾高压消毒，确保安全无菌，独家引进婴幼儿专用水疗机，具有气泡浴、涡流、恒温、冲浪、强排、臭氧消毒等多种功能，备受孩子们喜爱。'}}
      </p>
      <div class="airQuality">
        <p class="title">实时空气质量</p>
        <ul>
          <li>
            <p class="item">
              <img src="../../assets/infanttv/tem.png" alt="">
              <span style="color:#39EE4B">{{information.temperature?information.temperature:0}}</span>
              ℃
            </p>
            <p>温度</p>
          </li>
          <li>
            <p class="item">
              <img src="../../assets/infanttv/hum.png" alt="">
              <span :style="{color:information.humidity<=80?'#39EE4B':'#F55238'}">{{information.humidity?information.humidity:0}}</span>
              RH
            </p>
            <p>湿度</p>
          </li>
          <li>
            <p class="item">
              <img src="../../assets/infanttv/pm.png" alt="">
              <span :style="{color:information.pm25<=75?'#39EE4B':'#F55238'}">{{information.pm25?information.pm25:0}}</span>
              μg/m³
            </p>
            <p>PM2.5</p>
          </li>
        </ul>
        <p class="item">
          其他空气检测项：<span :style="{color:information.otherTarget?'#39EE4B':'#F55238'}">{{information.otherTarget?'合格':'不合格'}}</span>
        </p>
      </div>
      <div class="detection" v-if="information.module">
        <p class="title">检测项目</p>
        <el-row>
          <el-col :span="12">
            <p>视频监测<span><img v-if="information.module.indexOf(5) !== -1" src="../../assets/infanttv/check.png" alt=""></span></p>
          </el-col>
          <el-col :span="12">
            <p>消毒监测<span><img v-if="information.module.indexOf(2) !== -1" src="../../assets/infanttv/check.png" alt=""></span></p>
          </el-col>
          <el-col :span="12">
            <p>水质监测<span><img v-if="information.module.indexOf(1) !== -1" src="../../assets/infanttv/check.png" alt=""></span></p>
          </el-col>
          <el-col :span="12">
            <p>扫码管理<span><img v-if="information.module.indexOf(4) !== -1" src="../../assets/infanttv/check.png" alt=""></span></p>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfantMsg, getHealthList } from '@/api'
import Swiper, { Autoplay, Pagination } from 'swiper';
// import 'swiper/swiper.scss';

import 'swiper/swiper-bundle.css'
Swiper.use([Autoplay, Pagination])
export default {
  data () {
    return {
      imgList: [
        require('../../assets/infant/宣传图.png'),
        require('../../assets/infant/入场测温.png'),
        require('../../assets/infant/扫码管理.png'),
        require('../../assets/infant/空气监测.png'),
        require('../../assets/infant/消毒管理.png'),
        require('../../assets/infant/水质管理.png')
      ],
      pictures: [],
      information: {},
      refreshData: null,
      mySwiper: null
    };
  },
  created () {
    this.init()
  },
  mounted () {
    this.refreshData = setInterval(() => {
      this.init()
    }, 1000 * 30);
  },
  methods: {
    async init () {
      //  getHealthList().then(res => {
      //   console.log('res :>> ', res);
      // })
      const res = await getHealthList()
      const healthList = res.data
      getInfantMsg().then(res => {
        this.information = res.data
        // this.pictures = []
        // console.log('this.pictures :>> ', this.pictures);
        // debugger
        if (this.pictures.length === 0) {
          if (res.data.pictures && JSON.parse(res.data.pictures).length > 0) {
            JSON.parse(res.data.pictures).map(el => {
              this.pictures.push(el.url)
            })
          } else {
            this.imgList.map(el => {
              this.pictures.push(el)
            })
          }
          if (healthList.length > 0) {
            healthList.map(el => {
              this.pictures.push(`https://child.terabits.cn/${el.pictureFileName}`)
            })
          }
          this.initSwiper()
        }

        if (this.information.modules) {
          this.information.module = JSON.parse(this.information.modules)
        } else {
          this.information.module = []
        }
        if (this.information.hcho <= 0.1 && this.information.tvoc <= 261 && this.information.co2 <= 1500) {
          this.information.otherTarget = true
        } else {
          this.information.otherTarget = false
        }
        // console.log('object :>> ', this.information.hcho, this.information.tvoc, this.information.co2);

      })
    },
    initSwiper () {
      if (this.mySwiper) {
        this.mySwiper.destroy(true)
      }
      this.$nextTick(() => {
        if (this.imgList && this.imgList.length > 1) {
          this.mySwiper = new Swiper('.swiper_infant', {
            autoplay: {
              delay: 10000,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            slidesPerView: 'auto',
            loop: true,
            speed: 3000,
            pagination: {
              el: '.swiper-pagination',
              observer: true,//开启动态检查器，监测swiper和slide
              observeParents: true//监测Swiper 的祖/父元素
            }
          })
        }
        if (this.imgList && this.imgList.length <= 1) {
          this.mySwiper = new Swiper('.swiper_infant', {
            slidesPerView: 'auto',
            speed: 3000
          })
        }
      })
    }
  },
  beforeDestroy () {
    clearInterval(this.refreshData);
    this.refreshData = null;
  }
};
</script>

<style lang="scss">
.infantTv {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/infanttv/bg.png") no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 48px 90px 48px 69px;
  display: flex;
  align-items: center;
  .imgBox {
    width: 675px;
    height: 100%;
    background: rgba(255, 255, 255.5);
    border-radius: 24px 24px 25px 25px;
    padding: 30px;
    box-sizing: border-box;
    margin-right: 88px;
    .swiper_infant {
      width: 100%;
      height: 100%;
      .swiper-wrapper {
        width: 100%;
        height: 100%;
        .swiper-slide {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          img {
            width: 100%;
            // height: 100%;
            // line-height: 100%;
            border-radius: 24px 24px 25px 25px;
          }
        }
      }
      .swiper-pagination .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background: #ffffff;
        border-radius: 50%;
        opacity: 1;
      }
      .swiper-pagination .swiper-pagination-bullet-active {
        width: 22px;
        height: 10px;
        background: #22cdbf;
        border-radius: 5px 5px 5px 5px;
      }
    }
  }
  .introduce {
    flex: 1;
    // text-align: center;
    h2 {
      justify-content: center;
      font-size: 32px;
      font-weight: bold;
      color: #36405b;
      display: flex;
      align-items: center;
      i {
        display: inline-block;
        width: 68px;
        height: 68px;
        background: url("../../assets/infanttv/title.png") no-repeat;
        background-size: 100% 100%;
        margin-right: 18px;
      }
      span {
        color: rgba(34, 205, 191, 1);
        margin: 0 10px;
      }
    }
    > p {
      margin: 20px 0 28px;
      text-indent: 2em;
      font-size: 26px;
      font-weight: 500;
      color: #36405b;
      line-height: 42px;
    }
    .airQuality,
    .detection {
      width: 100%;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 24px 24px 25px 25px;
      padding-top: 1px;
      border: 1px solid rgba(188, 237, 236, 1);
      .title {
        margin: -9px auto 34px;
        width: 234px;
        height: 76px;
        line-height: 76px;
        text-align: center;
        background: url("../../assets/infanttv/box_title.png") no-repeat;
        background-size: 100% 100%;
        font-size: 28px;
        font-weight: 500;
        color: #36405b;
      }
    }
    .airQuality {
      padding-bottom: 40px;
      margin-bottom: 32px;
      ul {
        display: flex;
        justify-content: center;
        margin-bottom: 44px;
        li {
          text-align: center;
          margin-right: 73px;
          > p {
            font-size: 28px;
            font-weight: 500;
            color: rgba(54, 64, 91, 0.8);
          }
          .item {
            width: 220px;
            span {
              font-size: 42px;
              color: #39ee4b;
              margin: 0 13px;
            }
          }
        }
        li:last-child {
          margin: 0;
        }
      }
      .item {
        width: 340px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        font-size: 28px;
        font-weight: 500;
        color: #36405b;
        background: #e1e1e1;
        border-radius: 30px 30px 30px 30px;
        span {
          // font-size: 42px;
          color: #39ee4b;
          // margin: 0 13px;
        }
      }
    }
    .detection {
      // ul {
      //   display: flex;
      //   flex-wrap: wrap;
      padding: 1px 90px;
      box-sizing: border-box;
      .el-col {
        margin-bottom: 40px;
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 28px;
          font-weight: 500;
          color: #36405b;
          span {
            margin-left: 31px;
            display: inline-block;
            width: 26px;
            height: 26px;
            background: #ffffff;
            border: 2px solid #b6b6b6;
            border-radius: 6px;
            position: relative;
            img {
              width: 34px;
              height: 32px;
              position: absolute;
              left: 1px;
              // bottom: 2px;
              top: -5px;
            }
          }
        }
      }
      // li:nth-of-type(2n-1) {
      //   margin-right: 190px;
      // }
      // }
    }
  }
}
</style>
